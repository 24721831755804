import { FC } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'next-i18next'
import { TopPageUserCard } from '@components-v2/molecules/TopPageUserCard'
import ArrowSvg from '@/public/images/common/arrow-narrow-right_v2.svg'
import { Button2 } from '@components-v2/atoms/Button2'
import { GeneralSection } from '@/componentsV2/organisms/GeneralSection'
import { IUserBase } from '@/types'
import { getShortText, getUserAvatar } from '@/utils'
import styles from './index.module.scss'

type Props = {
  items: IUserBase[]
}

export const TopPageAngelsSection: FC<Props> = observer(({ items }) => {
  const { t } = useTranslation(['index'])
  const Footer = () => (
    <Button2
      href='/angels'
      componentType='a'
      size='xl'
      hierarchy='secondary-color'
      rightIcon={<ArrowSvg viewBox='0 0 24 24' />}
    >
      {t('button.viewAllAngels')}
    </Button2>
  )

  return (
    <GeneralSection heading={t('title.angelInvestors')} withDivider footer={<Footer />}>
      <div className={styles.angelList}>
        {items.map((user) => (
          <TopPageUserCard
            key={user?.username}
            name={user?.name}
            slug={user?.username}
            avatar={getUserAvatar(user)}
            description={getShortText(user?.profile?.bio, 50)}
            jobTitle={getShortText(user?.profile?.jobTitle, 40)}
          />
        ))}
      </div>
    </GeneralSection>
  )
})
