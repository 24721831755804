import { FC } from 'react'
import { sanitizeUrl } from '@/utils'
import styles from './index.module.scss'

export type TopPageSupport = {
  title: string
  meta: string
  imgSrc: string
  href: string
  description: string
}

type Props = {
  support: TopPageSupport
}

export const TopPageSupportCard: FC<Props> = ({ support }) => {
  return (
    <a className={styles.container} href={sanitizeUrl(support.href)} target='_blank' rel='noreferrer'>
      <div className={styles.thumbnail}>
        <img src={support.imgSrc} alt={support.title} />
      </div>
      <div className={styles.content}>
        <p className={styles.meta}>{support.meta}</p>
        <p className={styles.title}>{support.title}</p>
        <p className={styles.description}>{support.description}</p>
      </div>
    </a>
  )
}
