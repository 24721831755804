import { FC } from 'react'
import styles from './index.module.scss'
import { sanitizeUrl } from '../../../utils'

type Props = {
  src: string
  alt: string
  href: string
}

export const TopPageBanner: FC<Props> = ({ src, alt, href }) => {
  return (
    <a className={styles.container} href={sanitizeUrl(href)} target='_blank' rel='noreferrer'>
      <div className={styles.thumbnail}>
        <img src={src} alt={alt} className='img-fluid' />
      </div>
    </a>
  )
}
