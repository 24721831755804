import { FC } from 'react'
import { observer } from 'mobx-react'
import { sanitizeUrl } from '@/utils'
import Link from 'next/link'
import { Avatar2 } from '@components-v2/atoms/Avatar2'
import styles from './index.module.scss'

type Props = {
  name: string
  slug: string
  avatar: string
  description: string
  jobTitle: string
}

export const TopPageUserCard: FC<Props> = observer(({ name, slug, avatar, description, jobTitle }) => {
  return (
    <Link href={sanitizeUrl(`/users/${slug}`)}>
      <a className={styles.container} rel='noreferrer'>
        <div className={styles.image}>
          <Avatar2 size='xxl' src={avatar} />
        </div>
        <div className={styles.content}>
          <div className={styles.name}>{name}</div>
          <div className={styles.jobTitle}>{jobTitle}</div>
          <div className={styles.description}>{description}</div>
        </div>
      </a>
    </Link>
  )
})
