import { FC } from 'react'
import ArrowSvg from '@/public/images/common/arrow-narrow-right_v2.svg'
import { observer } from 'mobx-react'
import { useTranslation } from 'next-i18next'
import { Button2 } from '@components-v2/atoms/Button2'
import { GeneralSection } from '@/componentsV2/organisms/GeneralSection'
import { GridCardList } from '@/componentsV2/molecules/GridCardList'
import { TopPageSupport, TopPageSupportCard } from '@/componentsV2/molecules/TopPageSupportCard'
import styles from './index.module.scss'

export const TopPageGrowthSupport: FC = observer(() => {
  const { t } = useTranslation(['index'])
  const items: TopPageSupport[] = [
    {
      title: '法務支援',
      meta: 'スタートアップ成長支援',
      description:
        'XP法律事務所は、スタートアップ向けの法律サポートを、月額1万円から提供しています。契約書や登記変更、商標登録、労務関連問題など、日常的にお使いのコミュニケーションツールを活用してスムーズに対応いたします。',
      href: 'https://xp-law.com/startup',
      imgSrc: '/images/toppage/legal-support.png',
    },
    {
      title: 'M&A支援',
      meta: 'スタートアップ成長支援',
      description:
        'PRO M&Aは、スタートアップ企業のM&Aプロセスをサポートする専門会社と連携し、M&Aを通じてスタートアップのExitと成長を効果的に促進いたします。',
      href: 'https://ma.protocol.ooo/',
      imgSrc: '/images/toppage/ma.png',
    },
    {
      title: 'IT導入補助金申請支援',
      meta: 'スタートアップ成長支援',
      description:
        'エンドライブ株式会社では、下記のITツール及び補助金申請まで一気通貫してサポートし、業務効率化・売上UPを支援いたします。*設立1年後で直近1期分の決算書が必要',
      href: 'https://prosupporters.protocol.ooo/itgrants',
      imgSrc: '/images/toppage/grant.png',
    },
  ]
  const Footer = () => (
    <Button2
      href='https://prosupporters.protocol.ooo/'
      componentType='a'
      size='xl'
      hierarchy='secondary-color'
      rightIcon={<ArrowSvg viewBox='0 0 24 24' />}
    >
      {t('button.viewAllSupports')}
    </Button2>
  )
  return (
    <GeneralSection heading={t('title.growthSupport')} withDivider footer={<Footer />}>
      <div className={styles.itemList}>
        <GridCardList>
          {items.map((item) => (
            <TopPageSupportCard key={item.title} support={item} />
          ))}
        </GridCardList>
      </div>
    </GeneralSection>
  )
})
