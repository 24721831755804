import { FC } from 'react'
import { TopPageBanner } from '@components/molecules/TopPageBanner'
import { useRouter } from 'next/router'
import styles from './index.module.scss'

type BannerItem = {
  src: string
  alt: string
  href: string
}

const bannerItemsEN: BannerItem[] = [
  {
    src: '/images/common/pro-supporters-banner-en.png',
    alt: 'PRO Supporters',
    href: 'https://prosupporters.protocol.ooo/',
  },
  {
    src: '/images/common/pro-ma-banner-en.png',
    alt: 'PRO M&A',
    href: 'https://ma.protocol.ooo/1-1',
  },
  {
    src: '/images/common/pro-raise-banner-en.png',
    alt: 'PRO Raise',
    href: 'https://prosupporters.protocol.ooo/proraise',
  },
]

const bannerItemsJA: BannerItem[] = [
  {
    src: '/images/common/pro-supporters-banner-ja.png',
    alt: 'PRO Supporters',
    href: 'https://prosupporters.protocol.ooo/',
  },
  {
    src: '/images/common/pro-ma-banner-ja.png',
    alt: 'PRO M&A',
    href: 'https://ma.protocol.ooo/1',
  },
  {
    src: '/images/common/pro-invest-banner-ja.png',
    alt: 'PRO Invest',
    href: 'https://prosupporters.protocol.ooo/proinvest',
  },
]

export const TopPageBannerList: FC = () => {
  const router = useRouter()
  const bannerItems = router.locale === 'en' ? bannerItemsEN : bannerItemsJA
  return (
    <div className='container'>
      <div className={styles.container}>
        {bannerItems.map((item) => {
          return (
            <div key={item.src} className={styles.item}>
              <TopPageBanner src={item.src} alt={item.alt} href={item.href} />
            </div>
          )
        })}
      </div>
    </div>
  )
}
