import { observer } from 'mobx-react'
import { FC } from 'react'
import { ConsultingPromotionPopup } from '@components/organisms/ConsultingPromotionPopup'
import { PrimaryUserType } from '@/types'
import { useStores } from '@/utils'

export const TopPageConsultingPromotionPopup: FC = observer(() => {
  const { viewer } = useStores()

  // PrimaryUserType はログイン時以外は Unknown のためログイン時のみ表示で SignUpPromotionPopup とは被らない
  if (viewer.viewer.primaryUserType === PrimaryUserType.STARTUP_FOUNDER) {
    return <ConsultingPromotionPopup />
  }

  return <div />
})
