import { FC } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'next-i18next'
import { Button2 } from '@components-v2/atoms/Button2'
import ArrowSvg from '@/public/images/common/arrow-narrow-right_v2.svg'
import { TopPageCompanyCard } from '@components-v2/molecules/TopPageCompanyCard'
import { GeneralSection } from '@/componentsV2/organisms/GeneralSection'
import { ICompanyBase } from '@/types'
import { getCompanyLogo, getShortText } from '@/utils'
import styles from './index.module.scss'

type Props = {
  items: ICompanyBase[]
}

export const TopPageVcsSection: FC<Props> = observer(({ items }) => {
  const { t } = useTranslation(['index'])
  const Footer = () => (
    <Button2
      href='/vcs'
      componentType='a'
      size='xl'
      hierarchy='secondary-color'
      rightIcon={<ArrowSvg viewBox='0 0 24 24' />}
    >
      {t('button.viewAllVcs')}
    </Button2>
  )

  return (
    <GeneralSection heading={t('title.ventureCapitals')} withDivider footer={<Footer />}>
      <div className={styles.vcList}>
        {items.map((vc) => (
          <TopPageCompanyCard
            key={vc.slug}
            name={vc.name}
            slug={vc.slug}
            logo={getCompanyLogo(vc)}
            description={getShortText(vc.aboutUs, 50)}
          />
        ))}
      </div>
    </GeneralSection>
  )
})
