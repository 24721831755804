import { FC } from 'react'
import { useTranslation, Trans } from 'next-i18next'
import { observer } from 'mobx-react'
import { useStores } from '@/utils'
import { Button2 } from '@components-v2/atoms/Button2'
import styles from './index.module.scss'

export const TopPageHero: FC = observer(() => {
  const { t } = useTranslation(['index'])
  const { viewer } = useStores()

  return (
    <div className={styles.container}>
      <div className='container'>
        <div className={styles.content}>
          <div className={styles.upper}>
            <div className={styles.heading}>Asian Startup Community</div>
            <div className={styles.text}>
              <Trans i18nKey='index:heroテキスト'>
                PROTOCOLは、スタートアップ特化型SNSです。
                <br />
                日本中・世界中の投資家と繋がることができます。
              </Trans>
            </div>
          </div>

          {!viewer.isSignedIn && (
            <div className={styles.bottom}>
              <Button2 href='/signup' hierarchy='primary' size='xxl' componentType='a'>
                {t('無料で登録する')}
              </Button2>
            </div>
          )}
        </div>
      </div>
    </div>
  )
})
