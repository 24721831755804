import { observer } from 'mobx-react'
import { Trans, useTranslation } from 'next-i18next'
import { FC } from 'react'
import Link from 'next/link'
import styles from './index.module.scss'
import { useStores } from '../../../utils'
import { CloseButton } from '../../atoms/CloseButton'

type Props = {
  type?: 'jobSeeker' | 'investor' | 'startup'
}

export const SignUpPromotionPopupContent: FC<Props> = observer(({ type }) => {
  const { t } = useTranslation(['p'])
  const { ui } = useStores()

  const toggleModal = () => {
    ui.toggleSignUpModal()
  }

  const href = `/signup`
  let [title, text] = [
    `スタートアップや投資家に<br />アピールしよう`,
    'PROTOCOL にサインアップして資金調達やソーシングのチャンスを広げましょう',
  ]
  if (type === 'jobSeeker') {
    title = `サインアップして<br />企業にアピールしよう`
    text = 'サインアップしてプロフィールを記入することで、採用担当者へアピールするチャンスを広げることができます。'
  }
  if (type === 'investor') {
    title = `サインアップして<br />スタートアップにアピールしよう`
    text = 'サインアップしてプロフィールを記入することで、投資依頼等のチャンスを広げることができます。'
  }
  if (type === 'startup') {
    title = `サインアップして<br />投資家にアピールしよう`
    text = 'サインアップしてプロフィールを記入することで、資金調達等のチャンスを広げることができます。'
  }

  return (
    <>
      <div className={styles.heading}>
        <div className={styles.title}>
          {type && (
            <Trans
              i18nKey={
                type === 'startup' ? 'サインアップして投資家にアピールしよう' : 'サインアップして企業にアピールしよう'
              }
              ns='p'
            >
              {title}
            </Trans>
          )}
          {!type && (
            <Trans i18nKey='スタートアップや投資家にアピールしよう' ns='p'>
              {title}
            </Trans>
          )}
        </div>
        <div className={styles.closeButton}>
          <CloseButton onClick={toggleModal} />
        </div>
      </div>
      <div className={styles.body}>
        <div className={styles.text}>{t(text)}</div>
      </div>
      <Link href={href}>
        <a className={styles.link}>
          <span className={styles.linkText}>{t('サインアップ')}</span>
        </a>
      </Link>
    </>
  )
})
