import { observer } from 'mobx-react'
import { FC, useEffect } from 'react'
import { useStores } from '@/utils'
import { SignUpPromotionPopupContent } from '@components/molecules/SignUpPromotionPopupContent'
import styles from './index.module.scss'

type Props = {
  type?: 'jobSeeker' | 'investor' | 'startup'
}

export const SignUpPromotionPopup: FC<Props> = observer(({ type }) => {
  const { ui, viewer } = useStores()

  useEffect(() => {
    if (!viewer.isSignedIn && ui.isSignUpModalOpen === false) {
      ui.toggleSignUpModal()
    }
  }, [viewer.isInitialized])

  return (
    <div className={styles.container} style={!viewer.isSignedIn && ui.isSignUpModalOpen ? {} : { display: 'none' }}>
      <div className={styles.modal}>
        <div className={styles.card}>
          <SignUpPromotionPopupContent type={type} />
        </div>
      </div>
    </div>
  )
})
