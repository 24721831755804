import { FC, MouseEventHandler } from 'react'
import styles from './index.module.scss'

type Props = {
  onClick: MouseEventHandler<HTMLButtonElement>
  isLarge?: boolean
}

export const CloseButton: FC<Props> = ({ onClick, isLarge = false }) => {
  return (
    <button type='button' onClick={onClick} className={isLarge ? styles.largeButton : styles.button}>
      <img src='/images/common/close_icon.svg' alt='Close' className='img-fluid' />
    </button>
  )
}
