import { observer } from 'mobx-react'
import { FC, useState } from 'react'
import { CloseButton } from '@components/atoms/CloseButton'
import Link from 'next/link'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import styles from './index.module.scss'

export const ConsultingPromotionPopup: FC = observer(() => {
  const [isOpen, setIsOpen] = useState(true)
  const { t } = useTranslation(['global'])
  const router = useRouter()
  const href =
    router.locale === 'ja' ? process.env.NEXT_PUBLIC_CONTACT_SALES_JA_URL : process.env.NEXT_PUBLIC_CONTACT_SALES_EN_URL

  return (
    <div className={styles.container} style={isOpen ? {} : { display: 'none' }}>
      <div className={styles.modal}>
        <div className={styles.card}>
          <div className={styles.heading}>
            <div className={styles.title}>{t('グロースについて相談する')}</div>
            <div className={styles.closeButton}>
              <CloseButton onClick={() => setIsOpen(false)} />
            </div>
          </div>
          <div className={styles.body}>
            <div className={styles.text}>
              {t('資金調達、販路拡大、デザイン、開発等お困りのことがありましたらいつでもお気軽にご連絡ください')}
            </div>
          </div>
          <Link href={href}>
            <a className={styles.link}>
              <span className={styles.linkText}>{t('相談する')}</span>
            </a>
          </Link>
        </div>
      </div>
    </div>
  )
})
