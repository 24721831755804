import { GetStaticProps, NextPage } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { IArticleBase, ICompanyBase, IUserBase, Language, MessageType } from '@/types'
import { getStaticStores, useStores } from '@/utils'
import { PageWithHeroTemplate } from '@components-v2/templates/PageWithHeroTemplate'
import { TopPageHero } from '@components-v2/organisms/TopPageHero'
import { MyHead } from '@components/molecules/MyHead'
import { SignUpPromotionPopup } from '@components/organisms/SignUpPromotionPopup'
import { TopPageAngelsSection } from '@components-v2/organisms/TopPageAngelsSection'
import { TopPageFeaturesSection } from '@components-v2/organisms/TopPageFeaturesSection'
import { TopPageStoriesSection } from '@components-v2/organisms/TopPageStoriesSection'
import { TopPageBannerList } from '@components-v2/organisms/TopPageBannerList'
import { TopPageVcsSection } from '@components-v2/organisms/TopPageVcsSection'
import { TopPageGrowthSupport } from '@/componentsV2/organisms/TopPageGrowthSupportSection'
import { TopPageConsultingPromotionPopup } from '@components/organisms/TopPageConsultingPromotionPopup'
import styles from './index.module.scss'

type Props = {
  features: IArticleBase[]
  featuredStories: IArticleBase[]
  angels: IUserBase[]
  vcs: ICompanyBase[]
}

const Home: NextPage<Props> = ({ features, featuredStories, vcs, angels }) => {
  const { t } = useTranslation(['index'])
  const { messages } = useStores()
  const router = useRouter()

  useEffect(() => {
    // メールアドレス確認リンクを2回クリックした際のクエリパラメータをハンドリングする
    if (router.asPath === '/?error=invalid_confirmation_token') {
      messages.add({
        type: MessageType.Error,
        body: t('メールアドレス確認リンクが間違っています。この症状が続く場合はサポートへお問い合わせください。'),
        isTranslated: true,
        isDismissable: true,
      })
    } else if (router.asPath === '/?error=already_confirmed') {
      messages.add({
        type: MessageType.Info,
        body: t('このメールアドレスは確認済みです。'),
        isTranslated: true,
        isDismissable: true,
      })
    } else if (router.asPath === '/?left_service=true') {
      messages.add({
        type: MessageType.Info,
        body: t('退会しました。'),
        isTranslated: true,
        isDismissable: true,
      })
    }
  }, [])

  return (
    <PageWithHeroTemplate>
      <MyHead title={t('PROTOCOL')} isTitleWithSuffix={false} />
      <div className={styles.container}>
        <TopPageHero />
        {/* サーバー側で limit の処理を用意できてないのでスライス */}
        <TopPageVcsSection items={vcs.slice(0, 8)} />
        <TopPageAngelsSection items={angels.slice(0, 8)} />
        {router.locale === 'ja' && <TopPageGrowthSupport />}
        <TopPageFeaturesSection items={features.slice(0, 3)} />
        <TopPageStoriesSection items={featuredStories.slice(0, 3)} />
        <TopPageBannerList />
      </div>
      <SignUpPromotionPopup />
      <TopPageConsultingPromotionPopup />
    </PageWithHeroTemplate>
  )
}

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  const { viewer, articles, companies, users } = getStaticStores()

  await viewer.changeLanguage(locale as Language)
  const [features, featuredStoriesOutput, angels, vcs] = await Promise.all([
    articles.fetchPickedUpFeatures(6),
    articles.fetchFeaturedStories(6),
    users.fetchFeaturedAngels(8),
    companies.fetchFeaturedVentureCapitals(8),
  ])

  return {
    props: {
      features,
      featuredStories: featuredStoriesOutput.data.featuredStories,
      angels,
      vcs,
      forcedTheme: 'light',
      ...(await serverSideTranslations(locale, [
        'global',
        'index',
        'jobs',
        'companies-[id]',
        'p',
        'angels-vcs-startups',
      ])),
    },
    revalidate: 30,
  }
}

export default Home
